import ActionTypes from "../inc/ActionsTypes";
import Helper from "../inc/Helper";
import Settings from "../inc/Settings";

const DefaultState = {
    user:null,
    isLogin:false,
    isLoading:true
}
const AuthReducer = (state = DefaultState, actions) =>{
    if(actions.type ===  ActionTypes.SET_LOGIN ){
        state = {
            ...state,
            isLogin:true,
            user:actions.payload,
        }
    }
    if(actions.type ===  ActionTypes.SET_LOGOUT ){
        state = {
            ...state,
            isLogin:false,
            user:null,
        }
        const subdomainLocal = localStorage.getItem('subdomain')
        Helper.removeCookie(Settings.userTokenKey);
        window.location.href = `https://${subdomainLocal ? subdomainLocal : 'www'}.lynkaz.com/`
    }
    if(actions.type ===  ActionTypes.SET_USER ){
        state = {
            ...state,
            isLogin:true,
            user:actions.payload
        }
    }
    if(actions.type ===  ActionTypes.RESET_USER ){
        state = {
            ...state,
            user:null
        }
    }
    if(actions.type ===  ActionTypes.SET_AUTH_LOADING ){
        state = {
            ...state,
            isLoading: actions.payload
        }
    }
    return state;
}
export default AuthReducer;