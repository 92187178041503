import axios from 'axios';
import $ from 'jquery';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import Cookies from "js-cookie";
class RandomApi {
  init(params) {
    this.paramsObj = params;
    this.fieldId = params.data.label;
    this.value = params.value ? params.value : '';

    this.inputbox = document.createElement('input');
    this.addClass(this.inputbox, 'form-control')
    this.inputbox.id = 'ajax_search_input_ddd';
    this.inputbox.type = 'text';

    this.input = document.createElement("select");
    this.placeHolderOption = document.createElement("option");
    this.placeHolderOption.value = "";
    this.placeHolderOption.text = "Select";
    this.input.appendChild(this.placeHolderOption);
    const att = document.createAttribute("class");
    att.value = "rs_grid_simple_dropdown";
    this.input.setAttributeNode(att);

    // create input box
    // this.inputbox = document.createElement("input")

    this.wraperSection = document.createElement('div');
    this.addClass(this.wraperSection, 'rs_barcodesearch h-100');
    this.wraperSection.appendChild(this.inputbox);
    // this.wraperSection.appendChild(this.input);

    let that = this;

    $('#rs_dropdown_lebel').on('change', function (e) {
      e.preventDefault();
      const fieldId = e.target.value;
      console.log(fieldId);
      that.getFieldData(fieldId)
    })

    if (params.data.field_id) {
      that.getFieldData(params.data.field_id);
    }

    this.inputbox.addEventListener('input', (e) => {
      console.log(e);
      this.value = e.target.value;
      console.log(this.value);
    })

    this.input.addEventListener('input', (e) => {
      console.log(e);
      this.value = e.target.value;
      console.log(this.value);
      that.inputbox.innerHTML = "";
    })
  }

  getFieldData = (fieldId) => {
    this.inputbox.innerHTML = "";
    this.paramsObj.api.showLoadingOverlay();
    let that = this;
    axios.get(Settings.baseUrl + `field-dictionary/details?field_id=${fieldId}`).then((res) => {
      that.paramsObj.api.hideOverlay();
      if (res.data.success) {
        const data = res.data.data;
        const apiEndpoint = res.data.data.api_endpoint;
        if (apiEndpoint) {
          that.getAPIEndpoint(data);
        }
        else{
          if ($(this.wraperSection).find('select').length) {
            this.wraperSection.removeChild(this.input)
          }
          this.wraperSection.appendChild(this.inputbox)
        }
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  getAPIEndpoint = (apiurl) => {
    let that = this;
    let api = Api;
    api.setUserToken();
    const subdomainCookies = Cookies.get('lynkaz_workflow');
    const apiEndpoint = apiurl.api_endpoint[0] === "/" ? apiurl.api_endpoint : `/${apiurl.api_endpoint}`
    const dynamicEndpoint = `https://${subdomainCookies}.lynkaz.com${apiEndpoint}`;
    api.axios().get(dynamicEndpoint).then((res) => {
      if (res.status === 200) {
        const data = res.data.data;
        const objectData = {
          endpointKey: apiurl.endpoint_key,
          endpointValue: apiurl.endpoint_value
        }
        const test = []
        data.forEach((item) => {
          test.push({
            ...item,
            value: item[objectData['endpointKey']],
            label: item[objectData['endpointValue']]
          })
        });
        if (test) {
          // const selectType = document.createElement("select");
          this.wraperSection.appendChild(this.input)
          if ($(this.wraperSection).find('input').length) {
            this.wraperSection.removeChild(this.inputbox)
          }
          if ($(this.input).find('option').length > 1) {
            $(this.input).empty();
            this.placeHolderOption = document.createElement("option");
            this.placeHolderOption.value = "";
            this.placeHolderOption.text = "Select";
            this.input.appendChild(this.placeHolderOption);
          }
          test.forEach((element) => {
            let tempOption = document.createElement('option');
            tempOption.label = element.label;
            tempOption.value = element.value;
            // that.datalist.appendChild(tempOption)
            // this.input.appendChild(tempOption);
            this.input.appendChild(tempOption);
          })

        }
      }
    }).catch((res) =>
      console.log(res)
    )
  }

  addClass(selector, className) {
    const att = document.createAttribute("class");
    att.value = className;
    selector.setAttributeNode(att);
  }

  /* Component Editor Lifecycle methods */
  // gets called once when grid ready to insert the element
  getGui() {
    return this.wraperSection;
  }

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached() {
    this.input.focus();
  }
  focusOut() {
    this.sboxDropdwon.innerHTML = "";
    return true;
  }
}
export default RandomApi;