import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AgGrid from '../../../../../components/grid/ag/ag-grid';
import Settings from '../../../../../inc/Settings';
import { conditionData, operatorData, havingData } from './InitialData';
import Helper from '../../../../../inc/Helper';
import RandomApi from '../../../../../components/grid/ag/cellEditor/RandomApi';
import Button from '../../../../../components/inc/Button';
import SimpleLoading from '../../../../../components/Loading/SimpleLoading';
import Popup from '../../../../../components/Popup/Popup';
import $, { error } from 'jquery';
import { ValidateArray } from '../../../../../inc/Validation';
import Input from '../../../../../components/Forms/Input';
import AgGridMemo from '../../../../../components/grid/ag/AgGridMemo';
import ConditionChecker from '../../../../../components/grid/ag/cellEditor/ConditionChecker';

const BasicSettings = (props) => {

  const query = new URLSearchParams(window.location.search);
  const templateId = query.get('id');
  const workflowBodyId = query.get('to_id');
  const getNodeId = () => `${+new Date()}`;

  const [loading, setLoading] = useState(false);
  const [fieldDictionaryData, setFieldDictionaryData] = useState([]);
  const [nodeLabel, setnodeLabel] = useState('');
  const [conditionalData, setConditionalData] = useState([]);
  const [isDisable,setIsDisable] = useState(false)
  // const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    getFieldData();
    getConditionData();
  }, [])

  const checkInstance = () => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + `instance/check-by-body-id?body_id=${workflowBodyId}`).then((res) => {
          if (res.status === 200) {
            return res.data.instance_exist;
          }
        }
        )).catch((res) => reject(
          console.log(res)
        ))
    })
  }

  const getConditionData = () => {
    if (props.onClickNode) {
      axios.get(Settings.baseUrl + `workflow-condition/get?body_id=${workflowBodyId}&node_id=${props.onClickNode}`).then((res) => {
        if (res.data.success) {
          setLoading(false);
          if (res.data.data[0]?.node_id) {
            const conditionData = res.data.data;
            const newconditionDataArry = conditionData.map((item) => { return { ...item } });
            setConditionalData(newconditionDataArry);
            // setForceRender(!forceRender);
          }
        }
      }).catch((error) => {
        console.log(error);
        Helper.alert(error.message + "!", 'error');
        setLoading(false);
      })
    }
  }
  const getFieldData = () => {
    axios.get(Settings.baseUrl + `template/details?template_id=${templateId}`).then((res) => {
      if (res.data.success) {
        const data = res.data.data.field_dictionary;
        let moduleData = [];
        data.forEach((item) =>
          moduleData.push({
            value: item.field_id,
            label: item.field_label
          }))
        setFieldDictionaryData(moduleData);
        // setForceRender(!forceRender);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  // $('body').on('change', '#rs_dropdown', (event) => {
  //   console.log("value: ", event.target.value)
  // })

  const gridValidationRules = {
    clause: { name: 'clause', displayName: 'Clause', types: ['Required'] },
    field_id: { name: 'field_id', displayName: 'Field', types: ['Required'] },
    operator: { name: 'operator', displayName: 'Operator', types: ['Required'] },
    value: { name: 'value', displayName: 'Value', types: ['Required'] }
  }

  const Workflow_Activity_Headings = [
    { field: 'clause', headerName: 'Clause', inputType: "dropdown", options: conditionData, validationRule: gridValidationRules.clause },
    { field: 'field_id', headerName: 'Field', inputType: "dropdown", options: fieldDictionaryData, inputId: "rs_dropdown_lebel", validationRule: gridValidationRules.field_id },
    { field: 'operator', headerName: 'Operator', cellEditor: ConditionChecker, inputType: "dropdown", options: operatorData, validationRule: gridValidationRules.operator },
    { field: 'value', headerName: 'Value', cellEditor: RandomApi, validationRule: gridValidationRules.value },
  ]

  //Call API for create conditional decision.

  const createConditionHandler = async (data) => {
    if (props.onClickNode) {
      const value = await checkInstance();
      if (value) {
        Helper.alert("This workflow have instance, so you can't delete", 'error');
        getConditionData();
      } else {
        nodeEditHandler(data);
        return;
      }
      return;
    }
    let parentCondition;
    if (props.currentHandleId === 'a') {
      parentCondition = true;
    } else {
      parentCondition = false;
    }
    const nodid = getNodeId();
    let validation = ValidateArray(data);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error')
    }

    let tableData = []
    if (data[0].clause !== '1') {
      Helper.alert("Can't add 'AND/OR' clause at the starting of condition", 'error');
      return
    }
    data.forEach(item => {
      if (item.condition_id > 0) {
        tableData.push(
          {
            "clause": +item.clause,
            "field_id": +item.field_id,
            "operator": +item.operator,
            "value": item.value,
            "condition_id": item.condition_id
          })
      } else {
        tableData.push({
          "clause": +item.clause,
          "field_id": +item.field_id,
          "operator": +item.operator,
          "value": item.value
        })
      }
    });
    const formData = {
      "body_id": +workflowBodyId,
      "conditions": tableData,
      "node_id": nodid,
      "parent_node_id": props.currentNode !== "1" ? +props.currentNode : null,
      "parent_condition": props.currentHandleId ? parentCondition : null,
    }
    axios.post(Settings.baseUrl + 'workflow-condition/create', formData).then((res) => {
      if (res.data.success) {
        console.log(res);
        Helper.alert(res.data.message);
        props.hideConditionalModal(false);
        props.mainModal(false);
        props.addNode(props.currentNode, props.actionValue, props.currentHandleId, nodid, nodeLabel);
        setTimeout(() => { props.save() }, 100);
      } else {
        console.log("First fill the conditional field data");
        Helper.alert("First fill the conditional field data", error);
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  const addNodeAfterModalClose = () => {
    $('.rs_form_grid .rs_grid_btn_save').trigger('click');
  }

  const modalClose = () => {
    setIsDisable(false)
    props.hideConditionalModal(false);
    props.setOnClickNode();
    // setTimeout(() => { props.save() }, 100);
  }

  const nodeDeleteHandler = async () => {
    setIsDisable(true)
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't delete", 'error');
      setIsDisable(false)
    } else {
      const formData = { "node_id": props.onClickNode };
      axios.post(Settings.baseUrl + 'workflow-node/delete', formData).then((res) => {
        if (res.data.success) {
          console.log(res);       
          props.setDelete(true);
          modalClose();
        }
      }).catch((error) => {
        setIsDisable(false)
        console.log(error)
      })
    }
  }

  const conditionDeleteHandler = async (event) => {
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't delete", 'error');
      getConditionData();
    } else {
      let data = [];
      event.forEach((item) => {
        console.log(item);
        if (!item.gridRowId) {
          data.push(item.condition_id)
        }
      });
      const formData = {
        "node_id": props.onClickNode,
        "condition_id": data
      }
      console.log(formData);
      axios.post(Settings.baseUrl + 'workflow-condition/delete', formData).then((res) => {
        if (res.data.success) {
          console.log(res);
          Helper.alert(res.data.message);
          getConditionData();
        }
      }).catch((error) =>
        console.log(error)
      )
    }
  }

  const nodeEditHandler = (rowItem) => {
    let clauseCheck = false;
    console.log(conditionalData, rowItem);
    let newData = [];
    let updateData = [];
    rowItem.forEach((item) => {
      if (item.gridRowId) {
        if (item.clause === '1') {
          Helper.alert("Can't add 'Where' clause at the middle of condition", 'error');
          clauseCheck = true;
        } else {
          newData.push({
            "clause": +item.clause,
            "field_id": +item.field_id,
            "operator": +item.operator,
            "value": item.value,
          })
        }
      } else {
        console.log(item.body_id, conditionalData[0].body_id);
        if (item.condition_id === conditionalData[0].condition_id) {
          if (item.clause !== '1') {
            Helper.alert("Can't change the 'Where' clause at start of condition", 'error');
            clauseCheck = true;
          } else {
            updateData.push({
              "clause": +item.clause,
              "field_id": +item.field_id,
              "operator": +item.operator,
              "value": item.value,
              "condition_id": item.condition_id
            })
          }
        }
        if (item.condition_id !== conditionalData[0].condition_id) {
          if (item.clause === '1') {
            Helper.alert("Can't add the 'Where' clause at middle of condition", 'error');
            clauseCheck = true;
          } else {
            updateData.push({
              "clause": +item.clause,
              "field_id": +item.field_id,
              "operator": +item.operator,
              "value": item.value,
              "condition_id": item.condition_id
            })
          }
        }
      }
    })
    const formData = {
      "node_id": props.onClickNode,
      "create_data": newData,
      "update_data": updateData
    }
    if (!clauseCheck) {
      axios.post(Settings.baseUrl + 'workflow-condition/update', formData).then((res) => {
        if (res.data.success) {
          Helper.alert(res.data.message);
          getConditionData();
        }
      }).catch((error) =>
        console.log(error)
      )
    }

  }

  return (
    <>
      <Popup onClose={modalClose} autoOpen={true} >
        <h3>Conditional decision</h3>
        <p>You must define a condition for conditional decision</p>
        {/* {!props.onClickNode &&
          <Input label="Label" onChange={(e) => { setnodeLabel(e.target.value) }} required value={nodeLabel} />
        } */}
        <div className='rs_form_grid'>
          <AgGrid data={conditionalData} header={Workflow_Activity_Headings} hideHeaderRightContents={true} onSave={createConditionHandler} onDelete={conditionDeleteHandler} lastRowEdit={true} />
        </div>
        <div className='d-flex justify-content-end mt-4 '>
          <Button className="me-1" title="Cancel" onClick={modalClose} />
          {props.onClickNode && <Button className="mx-1" title="Delete" isDisable={isDisable} onClick={nodeDeleteHandler}/>}
          <Button className="ms-1" title='Save' onClick={addNodeAfterModalClose} icon={'/images/icons/save-white.svg'} isActive={true}/>
        </div>
      </Popup>
    </>
  )
}

export default BasicSettings;