import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import RsWithRouter from '../inc/RsWithRouter';
import SimpleLoading from '../Loading/SimpleLoading';
import Settings from '../../inc/Settings';
import Cookies from 'js-cookie';

class LogoutComponent extends Component {
    componentDidMount(){
        this.doLogout();
    }
    doLogout(){
        this.props.logOut();
    }
    render() {
        const subdomainCookies = Cookies.get('lynkaz_workflow');
        if(!this.props.auth.isLogin){
            // this.props.rs_router.navigate('/')
            window.location.href = `https://${subdomainCookies ? subdomainCookies : 'www'}.lynkaz.com/`;
        }
        return (
            <SimpleLoading/>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logOut:() => {
            dispatch({type:ActionTypes.SET_LOGOUT}) 
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) ( RsWithRouter(LogoutComponent) );