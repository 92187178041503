import React, { useEffect, useState } from 'react'
import MasterComonent from '../../../components/Backend/MasterComonent';
import AgGridMemo from '../../../components/grid/ag/AgGridMemo';
import NavigationHeder from '../../../components/Navigations/NavigationHeder';
import Button from '../../../components/inc/Button';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import axios from 'axios';
import Helper from '../../../inc/Helper';

const Instances = () => {
  const [failedInstaces, setFailedInstaces] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [instanceId, setInstanceId] = useState(false);

  useEffect(() => {
    getFailedInstances();
  }, [])

  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            console.log(res);
            return (res.data.data.tenant_id);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const getFailedInstances = async () => {
    const tenantId = await getTenantId();
    axios.get(Settings.baseUrl + `instance/failed?tenant_id=${tenantId}`).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setFailedInstaces(res.data.data);
        setForceRender(!forceRender);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const getRowData = (event) => {
    let activeSelection = event.api.getSelectedRows();
    if (activeSelection.length) {
      setInstanceId(activeSelection[0].instance_id);
    } else {
      setInstanceId(null);
    }
  }

  const restartWorkflow = () => {
    if (!instanceId) {
      Helper.alert("Select a workflow for restart", 'error');
      return;
    }
    axios.post(Settings.baseUrl + `instance/restart`, { 'instance_id': instanceId }).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
      };
    }).catch((error) => {
      console.log(error);
    })
  }

  const Failed_Instances_Table_Column_Headings = [
    { field: 'workflow_name', headerName: 'Workflow Name' },
    { field: 'template_name', headerName: 'Template Name' },
    { field: 'workflow_version', headerName: 'Versions' },
    // { field: 'instance_id', headerName: 'Instances' },
    { field: 'trigger', headerName: 'Trigger Option' },
    { field: 'reason', headerName: 'Reason for failed', width: 650 },
    { field: 'module_item_id', headerName: 'Integrator' },
    { field: 'status', headerName: 'Status' },
    // { field: 'state', headerName: 'State' },
  ]

  return (
    <>
      <MasterComonent>
        <NavigationHeder hideBackButton={true} title={`<span style="color:#000;"> Home > </span>  ${'Failed Instances'}`}>
          <Button isActive={true} title='Restart' icon={'/images/icons/refresh-cw.svg'} onClick={restartWorkflow} />
        </NavigationHeder>
        <AgGridMemo data={failedInstaces} header={Failed_Instances_Table_Column_Headings} height={"750px"} allBtnHide={true} viewOnly={true} singleRowSelect={true} hideFooter={true} forceRender={forceRender} onSelectionChanged={getRowData} />
      </MasterComonent>
    </>
  )
}

export default Instances;