import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, totalRecord }) => {
  const handleClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const displayPaginationNumbers = () => {
    const tempAr = [];
    for (let i = 1; i <= totalPages; i++) {
      tempAr.push(
        <li
          key={i}
          className={currentPage === i ? "page-item active" : "page-item"}
          onClick={currentPage !== i ? () => handleClick(i) : null}
        >
          <span className="page-link" style={{ cursor: "pointer" }}>{i}</span>
        </li>
      );
    }
    return tempAr;
  };

  return (
    <nav className='d-flex align-items-center rs_grid_pagination justify-content-between'>
      <div>
        <p className='rs_grid_page_status'>Showing 10 of {totalRecord} entries</p>
      </div>
      <ul className="pagination">
        <li style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
          className={`page-item nex_prev ${currentPage === 1 ? "disabled" : ""}`}
          onClick={currentPage !== 1 ? () => handleClick(currentPage - 1) : null}
        >
          <span className="page-link">&laquo;</span>
        </li>
        {displayPaginationNumbers()}
        <li style={{ cursor: currentPage === totalPages ? "not-allowed" : "pointer" }}
          className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
          onClick={currentPage !== totalPages ? () => handleClick(currentPage + 1) : null}
        >
          <span className="page-link">&raquo;</span>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;