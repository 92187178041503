import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  MarkerType,
  addEdge,
  updateEdge,
} from 'reactflow';
import TextUpdaterNode from './TextUpdaterNode.js';
import 'reactflow/dist/style.css';
import WorkflowStartNode from '../Workflow Start Node/WorkflowStartNode';
import "./ReactFlow.css";
import { useDispatch, useSelector } from 'react-redux';
import { initialNodes, nodeColor } from './InitialData';
import UserHistorySection from '../Gantt Chart/Views/User History Section/UserHistorySection';
import ActiveUserSection from '../Gantt Chart/Views/Active User Section/ActiveUserSection';
import SimpleLoading from '../../../components/Loading/SimpleLoading';
import axios from 'axios';
import Settings from '../../../inc/Settings';
import BasicSettings from '../Conditional Decision Modal/Modal Tab Menu/Basic Settings/BasicSettings';
import AdvancedSettings from '../Conditional Decision Modal/Modal Tab Menu/Advanced settings/AdvancedSettings';
import Task from '../Conditional Decision Modal/Modal Tab Menu/Task/Task';
import Helper from '../../../inc/Helper.js';
import ButtonEdge from './ButtonEdge.js';

const nodeTypes = { textUpdater: TextUpdaterNode };
const edgeTypes = { buttonedge: ButtonEdge };

const WorkFlowCanvas = () => {
  const query = new URLSearchParams(window.location.search);
  const workflowBodyId = query.get('to_id');
  const dispatch = useDispatch();
  const edgeUpdateSuccessful = useRef(true);

  const isClose = useSelector((state) => state.workflowSlice.isOpend);
  const nodeNewId = useSelector((state) => state.workflowSlice.nodeId);
  const initilVal = useSelector((state) => state.workflowSlice.currentData);
  const initialEdges = useSelector((state) => state.workflowSlice.currentEdge);
  const isOptionModal = useSelector((state) => state.workflowSlice.isOptionModal);
  // const actionValue = useSelector((state) => state.workflowSlice.actionValue);
  // console.log(actionValue);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(false);
  const [conditionalModal, setConditionalModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [actionValue, setActionValue] = useState('');
  // const [conditionalData, setConditionalData] = useState([]);

  const positionY = useRef(0);
  const positionX = useRef(0);
  const [currentNode, setCurrentNodeId] = useState();
  const [currentHandleId, setCurrentHandleId] = useState();

  const onAdd = (currentNode, actionValue, currentHandleId, nodeId, nodeLabel) => {
    let nodeHandleId;
    if (currentHandleId === 'a') {
      nodeHandleId = 'True';
    } else {
      nodeHandleId = 'False';
    }
    positionY.current += 50;
    positionX.current += 150;
    let node_bgcolor = '';
    let node_label = '';
    switch (actionValue) {
      case '1':
        node_bgcolor = 'bg-green';
        node_label = 'Conditional Node'
        break;
      case '2':
        node_bgcolor = 'bg-blue';
        node_label = 'Action Node'
        break;
      case '3':
        node_bgcolor = 'bg-orange';
        node_label = 'Task Node'
        break;
      // case '4':
      //   node_bgcolor = 'bg-pink';
      //   node_label = 'Fields Node'
      //   break;
    }
    let newNode;
    if (actionValue === "1") {
      newNode = {
        id: nodeId,
        type: 'textUpdater',
        data: { label: `${node_label}` },
        position: {
          x: positionY.current,
          y: positionX.current
        },
        className: `action-type__step-node ${node_bgcolor}`
      }
    } else {
      newNode = {
        id: nodeId,
        data: { label: `${node_label + " " + 'for' + " " + nodeLabel}` },
        type: 'default',
        position: {
          x: positionY.current,
          y: positionX.current
        },
        className: `action-type__step-node ${node_bgcolor}`
      };
    }
    setNodes((nds) => (nds.concat(newNode).filter((item) => item.className !== "flow-add-wrapper")));
    setEdges((eds) => eds.concat({
      id: `eId-${newNode.id}`, source: currentNode, target: newNode.id, sourceHandle: currentHandleId, label: currentHandleId ? nodeHandleId : "", style: {
        strokeWidth: 2,
        // stroke: '#FF0072',
      }, markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        // color: '#FF0072',
      },
    }));
  };

  const [rfInstance, setRfInstance] = useState(null);
  const checkConnectWithAllNode = () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      flow.nodes = flow.nodes.filter((item) => (item.className !== 'flow-add-wrapper'));
      const conditionNodeData = flow.nodes.filter((item) => (item.type === "textUpdater"));
      const conditionNode = conditionNodeData.map(item => item.id);
      const edgeData = flow.edges;
      let data = [];
      conditionNode.map(element => {
        const araryData = edgeData.filter((item) => item.source === element);
        data.push(araryData.length);
      });
      const checkConditionNodeHaveTwoTask = data.every((item) => item === 2);
      const nodeData = flow.nodes.filter((item) => (item.type !== "output"));
      const id = nodeData.map(item => item.id);
      const source = edgeData.map(item => item.source).filter(Boolean);
      const notMatch = id.filter(id => !source.includes(id));
      return ({ flow, notMatch, checkConditionNodeHaveTwoTask });
    }
  }

  const onSave = () => {
    const allNodeAndEdgeData = checkConnectWithAllNode();
    const flow = allNodeAndEdgeData.flow;
    const checkConditionNodeHaveTwoTask = allNodeAndEdgeData.checkConditionNodeHaveTwoTask;
    const notMatch = allNodeAndEdgeData.notMatch;
    setLoading(true);
    let formData;
    if (checkConditionNodeHaveTwoTask && !notMatch.length) {
      formData = {
        "body_id": workflowBodyId,
        "body": flow,
        "design_completed": true
      }
    } else {
      formData = {
        "body_id": workflowBodyId,
        "body": flow,
      }
    }
    if (flow.edges.length) {
      axios.post(Settings.baseUrl + 'workflow-body/update', formData).then((res) => {
        if (res.data.success) {
          setLoading(false);
        }
      }).catch((error) => {
        console.log(error)
        setLoading(false);
      })
    }
  };

  const [isDelete, setDelete] = useState(false);

  const onRestore = () => {
    const restoreFlow = async () => {
      axios.get(Settings.baseUrl + `workflow-body/details?body_id=${workflowBodyId}`).then((res) => {
        if (res.data.success) {
          const flow = res.data.data.body;
          if (flow) {
            setNodes(flow.nodes);
            setEdges(flow.edges);
          }
          setLoading(false);
          setDelete(false);
        }
      }).catch((error) => {
        console.log(error)
        setLoading(false);
      })
    };
    restoreFlow();
  };

  useEffect(() => {
    onRestore();
  }, [setEdges, isDelete])

  const [startNodeModal, setStartNodeModal] = useState(false);

  const [ndData, setNddata] = useState(null);
  const openWorkFlowStartNode = (data) => {
    const allNodeAndEdgeData = checkConnectWithAllNode();
    let flow = allNodeAndEdgeData.flow;
    flow.edges = flow.edges.filter((item) => item.source === data['target']?.['dataset']?.['nodeid']);
    const filterEdgesData = flow.edges;
    let errorDataShow = false;
    if (!filterEdgesData.length && data['target']?.['dataset']?.['nodeid'] !== '2') {
      setStartNodeModal(true);
      errorDataShow = true;
    }
    if (filterEdgesData.length === 1) {
      if (filterEdgesData[0]?.sourceHandle) {
        setStartNodeModal(true);
        errorDataShow = true;
      }
    }
    if (!errorDataShow) {
      Helper.alert("You can't add any node from here", 'error');
    }
    setNddata(data);
    setCurrentNodeId(data['target']?.['dataset']?.['nodeid']);
    setCurrentHandleId(data['target']?.['dataset']?.['handleid']);
    // positionY.current += 50;
    // positionX.current += 150;
    // let newNode;
    // newNode = {
    //   id: getNodeId(),
    //   data: { label: <div className='flow-add-container'><WorkflowStartNode del={setStartNodeModal} openConditionalModal={setConditionalModal} openActionModal={setActionModal} openTaskModal={setTaskModal} getActionValue={setActionValue} nodeData={data} /></div> },
    //   position: { x: 289, y: 124 },
    //   className: "flow-add-wrapper",
    // }
    // setNodes((nds) => nds.concat(newNode));
  }

  const [toggleButton, setToggleButton] = useState(false);

  const showActionHistory = () => {
    console.log(12345);
    setToggleButton(true);
  }

  const [onClickNode, setOnClickNode] = useState();
  const showWorkflow = (event) => {
    if (event.target.innerText.startsWith('Task Node')) {
      setOnClickNode(event.target.dataset.id);
      setTaskModal(true);
    }

    if (event.target.innerText.startsWith('Conditional Node')) {
      setOnClickNode(event.target.dataset.id);
      setConditionalModal(true);
    }
  }

  const onConnect = (ev) => {
    if (ev.source === "1") {
      return;
    }
    if (!ev.sourceHandle) {
      const flow = rfInstance.toObject();
      flow.edges = flow.edges.filter(element => (element.source === ev.source));
      if (!flow.edges.length && ev.target === "2") {
        setEdges((eds) => addEdge({ ...ev, type: 'buttonedge' }, eds));
        setTimeout(() => { onSave() }, 100);
      }
    }
  }

  const updateEdgeHandler = (ev, event) => {
    const allNodeAndEdgeData = checkConnectWithAllNode();
    let flow = allNodeAndEdgeData.flow;
    flow.edges = flow.edges.filter((item) => item.id !== event.id);
    const filterEdgesData = flow.edges;
    axios.get(Settings.baseUrl + `instance/check-by-body-id?body_id=${workflowBodyId}`).then((res) => {
      if (res.status === 200) {
        const data = res.data.instance_exist;
        if (!data) {
          if (event.target === '2') {
            setEdges(filterEdgesData);
            setTimeout(() => { onSave() }, 100);
          }
        } else {
          Helper.alert("This workflow have a instance, so you can't edit", 'error');
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      <div className='d-flex'>
        <div className='mt-3 card' style={{ width: "100%", height: "68vh" }}>
          {startNodeModal ? <WorkflowStartNode del={setStartNodeModal} openConditionalModal={setConditionalModal} openActionModal={setActionModal} openTaskModal={setTaskModal} getActionValue={setActionValue} nodeData={ndData} /> : ""}
          {conditionalModal ? <BasicSettings setDelete={setDelete} setOnClickNode={setOnClickNode} onClickNode={onClickNode} currentHandleId={currentHandleId} mainModal={setStartNodeModal} hideConditionalModal={setConditionalModal} currentNode={currentNode} addNode={onAdd} actionValue={actionValue} save={onSave} /> : ""}
          {actionModal ? <AdvancedSettings mainModal={setStartNodeModal} hideActionModal={setActionModal} currentNode={currentNode} addNode={onAdd} actionValue={actionValue} save={onSave} /> : ""}
          {taskModal ? <Task setDelete={setDelete} setOnClickNode={setOnClickNode} onClickNode={onClickNode} currentHandleId={currentHandleId} mainModal={setStartNodeModal} hideTaskModal={setTaskModal} currentNode={currentNode} addNode={onAdd} actionValue={actionValue} save={onSave} /> : ""}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onClickConnectStart={openWorkFlowStartNode}
            onNodeDragStop={onSave}
            onInit={setRfInstance}
            onConnect={onConnect}
            // onDoubleClick={showActionHistory}
            onNodeClick={showWorkflow}
            // onDoubleClick={showWorkflow}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onEdgeClick={updateEdgeHandler}
          >
            <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
            {/* <Button className='btn-node-save' onClick={onSave}>
              {loading ? <SimpleLoading width='15px' title='Save' /> : 'Save'}
            </Button> */}
            {/* <Button className='btn-node-restore' onClick={onRestore}>Restore</Button> */}
            <Controls />
          </ReactFlow>
        </div>
        {toggleButton ?
          <div className={toggleButton ? 'active-main-container' : 'deactive-main-container'}>
            <div className='card mt-3 ms-3 p-3'>
              <button className='btn togglrArrow' onClick={() => setToggleButton(false)}>
                <img src='/images/icons/Vector (Stroke).svg' />
              </button>
              <ActiveUserSection />
            </div>
          </div> : ""
        }
      </div>
    </>
  );
}
export default WorkFlowCanvas;