import React, { useState } from 'react'
import Input from '../../../../../../components/Forms/Input';
import DropDownInput from '../../../../../../components/Forms/Dropdown';

const TimeLimit = (props) => {

  const days = [
    { value: 1, label: 'Hour' },
    { value: 2, label: 'Day' }
  ]

  return (
    <div className="card mb-3">
      <h5 className="card-header text-primary border-bottom">Select the deadline for the assigned user(s) to complete this task</h5>
      <div className="card-body">
        <div className="p-2">
          <div className='row'>
            <div className='row label-form'>
              <div className='col-lg-6 col-md-3'>
                <DropDownInput label="Task Duration Type" options={days} onChange={(e) => { props.getTimeLimitType(+e.target.value) }} id='duration_type_id' value={props.timeLimitType} description={"Select the unit for measuring the task's duration: Days or Hours. This choice determines whether the task duration is tracked on a daily or hourly basis."}/>
              </div>
              <div className='col-lg-6 col-md-3 position-relative'>
                <Input inputType='number' label="Expected Completion Time" id='completion_id' value={props.timeDuration} onChange={(e) => { props.getTimeLimit(+e.target.value) }} description={"Specify the maximum time allowed to complete the task, based on the selected Duration Type (Day or Hour). Tasks completed beyond this time are considered as having violated Key Performance Indicators (KPIs), though completion is still possible without escalation"}/>
              </div>
              <div className='col-lg-6 col-md-3'>
                <Input inputType='number' label="Task Escalation Time" id='escalation_id' value={props.escalationTime} onChange={(e) => { props.getEscalationTime(+e.target.value) }} description={"Enter the time after which an pending task should be escalated, exceeding the Expected Completion Time. Escalation ensures tasks are reassigned if not addressed within the stipulated timeframe. Note: Escalation Time must always be greater than the Expected Completion Time."}/>
              </div>              
            </div>
          </div>
          {/* <div className='mt-3 col-md-10'>
            <p>Days available per week</p>
            <div className='row'>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Monday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Tuesday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Wednesday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Thursday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Friday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Saturday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Sunday
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default TimeLimit;