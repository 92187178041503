import Cookies from "js-cookie";
const query = new URLSearchParams(window.location.search);
const subdomainParams = query.get('subdomain');
const subdomainCookies = Cookies.get('lynkaz_workflow');

let Settings = {
    baseUrl: 'https://lynkaz-workflow.azurewebsites.net/api/',
    apiBaseUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/system`, // assets
    loginUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/common`,
    apiUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/common`,
    apiOrgUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/org`,
    apiVendorUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/vendor`,
    apiItemUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/items`,
    apiPurchaseOrderUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/po`,
    apiProcurementSettingsUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/confi`,
    apiPurchaseRequisitionUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/pr`,
    apiUrls: {
        segments: `https://${subdomainParams ? subdomainParams : subdomainCookies}.lynkaz.com/financials`
    },
    defaultLang: 'en',
    userTokenKey: 'lynkaq_user_token',
    subdomainKey: 'lynkaz_workflow',
    dateFormateKey: 'lynkaq_dateformate',
    languageKey: 'app_lang',
    userTokenExpireDate: 1,
    redirectUrlAfterLogin: 'template',
    loginLink: 'login',
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    debug: true,
    defaultDateFormate: 'mm/dd/yyyy',
    source_id: {
        tenant: 1, warehouse: 4, site: 3, entity: 2, address: 5, vendor: 6, vendor_registration: 7, bank: 8
    },
    validator: {
        max: {
            code: 10,
            name: 80
        }
    }
}
export default Settings;
