import axios from 'axios';
import $ from 'jquery';
import Settings from '../../../../inc/Settings';
class ConditionChecker {
  init(params) {
    this.paramsObj = params;
    this.fieldId = params.data.label;
    this.optionValue = params.values ? params.values : '';
    this.value = params.value ? params.value : '';
    this.inputbox = document.createElement('input');
    this.addClass(this.inputbox, 'form-control')
    this.inputbox.id = 'ajax_search_input_ddd';
    this.inputbox.type = 'text';

    this.input = document.createElement("select");
    this.placeHolderOption = document.createElement("option");
    this.placeHolderOption.value = "";
    this.placeHolderOption.text = "Select";
    this.input.appendChild(this.placeHolderOption);
    const att = document.createAttribute("class");
    att.value = "rs_grid_simple_dropdown";
    this.input.setAttributeNode(att);

    this.wraperSection = document.createElement('div');
    this.addClass(this.wraperSection, 'rs_barcodesearch h-100');
    this.wraperSection.appendChild(this.inputbox);

    let that = this;

    $('#rs_dropdown_lebel').on('change', function (e) {
      e.preventDefault();
      const fieldId = e.target.value;
      that.getFieldData(fieldId)
    })

    if (params.data.field_id) {
      that.getFieldData(params.data.field_id);
    }

    this.inputbox.addEventListener('input', (e) => {
      this.value = e.target.value;
    })

    this.input.addEventListener('input', (e) => {
      this.value = e.target.value;
      console.log(this.value);
      that.inputbox.innerHTML = "";
    })
  }

  getFieldData = (fieldId) => {
    this.inputbox.innerHTML = "";
    this.paramsObj.api.showLoadingOverlay();
    let that = this;
    axios.get(Settings.baseUrl + `field-dictionary/details?field_id=${fieldId}`).then((res) => {
      that.paramsObj.api.hideOverlay();
      if (res.data.success) {
        const apiEndpoint = res.data.data.api_endpoint;
        if ($(this.wraperSection).find('input').length) {
          this.wraperSection.removeChild(this.inputbox)
        }
        if (!$(this.wraperSection).find('select').length) {
          this.wraperSection.appendChild(this.input)
        }
        if ($(this.input).find('option').length > 1) {
          $(this.input).empty();
          this.placeHolderOption = document.createElement("option");
          this.placeHolderOption.value = "";
          this.placeHolderOption.text = "Select";
          this.input.appendChild(this.placeHolderOption);
        }
        if (apiEndpoint) {
          const filterValue = this.optionValue.filter((item) => { return item.value < 3 });
          filterValue.forEach((element) => {
            let tempOption = document.createElement('option');
            tempOption.label = element.label;
            tempOption.value = element.value;
            this.input.appendChild(tempOption);
          })
        } else {
          this.optionValue.forEach((element) => {
            let tempOption = document.createElement('option');
            tempOption.label = element.label;
            tempOption.value = element.value;
            this.input.appendChild(tempOption);
          })
        }
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  addClass(selector, className) {
    const att = document.createAttribute("class");
    att.value = className;
    selector.setAttributeNode(att);
  }

  /* Component Editor Lifecycle methods */
  // gets called once when grid ready to insert the element
  getGui() {
    return this.wraperSection;
  }

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached() {
    this.input.focus();
  }
  focusOut() {
    this.sboxDropdwon.innerHTML = "";
    return true;
  }
}
export default ConditionChecker;