import React, { Component } from 'react';
import AuthFooter from '../../components/Auth/AuthFooter';
import AuthHeader from '../../components/Auth/AuthHeader';
import LoginRegisterTab from '../../components/Auth/LoginRegisterTab';
import './login-register.css';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import LoginForm from '../../components/Auth/Login/LoginForm';
import AuthCheck from '../../components/Auth/AuthCheck';
import SimpleLoading from '../../components/Loading/SimpleLoading';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Api from '../../inc/Api';

class Login extends Component {
    constructor() {
        super();
        this.getUserTokenFromURL();
    }

    checkAdminRole = () => {
        let api = Api;
        api.setUserToken()
        return new Promise((resolve, reject) => {
          let subdomainLocal = localStorage.getItem('subdomain')
          resolve(
            api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
              console.log("'/user_details' API called");
              if (res.data.Status === "Success") {
                console.log(res.data.data);
                let aditionalData = res.data.data;
                console.log("71" in aditionalData.roles);
                if(!("71" in aditionalData.roles) && !("79" in aditionalData.roles)) {
                    window.location.href = `https://${subdomainLocal ? subdomainLocal : 'www'}.lynkaz.com/`;
                } else {
                    Helper.setCookie("roles", JSON.stringify(aditionalData.roles),Settings.userTokenExpireDate);
                    Helper.setCookie("isadmin", aditionalData.admin_user ,Settings.userTokenExpireDate);
                    return <Navigate to='/template' />
                }
              }
            })).catch((error) => reject(
                window.location.href = `https://${subdomainLocal ? subdomainLocal : 'www'}.lynkaz.com/`
            ))
        })
    }

    getUserTokenFromURL() {
        const query = new URLSearchParams(window.location.search);
        const userToken = query.get('t');
        const subdomian = query.get('subdomain');
        if (userToken !== null) {
            Helper.setCookie(Settings.userTokenKey, userToken);
            Helper.setCookie(Settings.subdomainKey, subdomian);
            localStorage.setItem('subdomain', subdomian)
            this.checkAdminRole();
        }
        else {
            const subdomainCookies = Cookies.get('lynkaz_workflow');
            const tokenCookies = Cookies.get('lynkaq_user_token');
            if(subdomainCookies && tokenCookies) {
                // stay
                return <Navigate to='/template' />
            }
            else {
                // redirect
                const subdomainLocal = localStorage.getItem('subdomain')
                window.location.href = `https://${subdomainLocal ? subdomainLocal : 'www'}.lynkaz.com/`;
            }

        }
    }
    render() {
        return (
            <>
                {/* <div className='login_register_page'> */}
                <AuthCheck noAccessForLogin={"/" + Settings.redirectUrlAfterLogin} />
                {/* <AuthHeader /> */}
                {/* <LoginRegisterTab tab="login"/> */}
                <SimpleLoading />
                {/* <LoginForm /> */}
                {/* <AuthFooter /> */}
                {/* </div> */}
            </>
        );
    }
}

export default Login;