import React, { Component } from 'react';
import ActionTypes from '../../inc/ActionsTypes';
import {connect} from 'react-redux';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { Navigate } from 'react-router-dom';
class AuthCheck extends Component {
    constructor(props){
        super(props);
        this.state = {
            isUserChecked:false
        }
    }
    componentDidMount(){
        let api = Api;
        let auth = this.props.auth;
        let that = this;
        if(!auth.isLogin && api.getUserToken()){
            api.setJsonHeader();
            api.setUserToken();
            api.get(Settings.apiUrl+'/user_details',{},function({data}){ 
                if(data && data.Status === "Success"){
                    that.props.login(data.data);
                }
                that.setState({
                    isUserChecked:true
                })
            })
        }else{
            that.setState({
                isUserChecked:true
            })
        }
    }
    render() {
        if( !this.props.auth.isLogin && this.props.loginRequire && this.state.isUserChecked){
            return <Navigate to={ '/'+Settings.loginLink } />
        }
        if( this.props.auth.isLogin && this.props.noAccessForLogin){
            return <Navigate to={ this.props.noAccessForLogin} />
        }
        return (
            <>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        login:(user) => { dispatch({type:ActionTypes.SET_LOGIN , payload:user}) },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (  AuthCheck );
