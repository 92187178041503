import axios from 'axios';
import React, { useEffect, useState } from 'react'
import MasterComonent from '../../../../components/Backend/MasterComonent';
import AgGrid from '../../../../components/grid/ag/ag-grid';
import AgGridMemo from '../../../../components/grid/ag/AgGridMemo';
import DropDownInput from '../../../../components/Forms/Dropdown';
import Button from '../../../../components/inc/Button';
import NavigationHeder from '../../../../components/Navigations/NavigationHeder';
import Settings from '../../../../inc/Settings';
import { ValidateArray } from '../../../../inc/Validation';
import Helper from '../../../../inc/Helper';
import SimpleLoading from '../../../../components/Loading/SimpleLoading';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/Forms/Input';
import Api from '../../../../inc/Api';

const FieldDictionary = () => {

  const [loading, setLoading] = useState(true);
  const [fieldDictionaryData, setFieldDictionaryData] = useState({ fieldData: [] });
  const [moduleData, setModuleData] = useState([]);
  const [templateName, setTemplateName] = useState();
  const [templateNameDescription, setTemplateNameDescription] = useState();
  const [forceRender, setForceRender] = useState(false);
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const token = query.get('id')

  const sourceName = [
    { value: 1, label: 'String' },
    { value: 2, label: 'Number' },
    { value: 3, label: 'Boolean' },
    { value: 4, label: 'Date & Time' }
  ]

  const gridValidationRules = {
    field_name: { name: 'field_name', displayName: 'Field Name', types: ['Required'] },
    field_type: { name: 'field_type', displayName: 'Type', types: ['Required'] },
    field_label: { name: 'field_label', displayName: 'Field Label', types: ['Required'] }
  }

  const Template_Field_Dictionary_Tabele_Columns_Headings = [
    { field: 'field_name', headerName: 'Field Name', validationRule: gridValidationRules.field_name },
    { field: 'field_label', headerName: 'Field Label', validationRule: gridValidationRules.field_label },
    { field: 'api_endpoint', headerName: 'API Endpoint' },
    { field: 'endpoint_key', headerName: 'Endpoint Key' },
    { field: 'endpoint_value', headerName: 'Endpoint Value' },
    { field: 'field_type', headerName: 'Type', inputType: "dropdown", options: sourceName, validationRule: gridValidationRules.field_type },
  ]

  useEffect(() => {
    getFormData();
    getTenantId();
  }, [])

  // const [tenantId, setTenantId] = useState('');
  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data.tenant_id);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const getModuleData = () => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.apiUrl + `/source?for_workflow=${true}`).then((res) => {
          if (res.data.status === "success") {
            return res.data.data;
          }
        })).catch((res) => reject(
          console.log(res)
        ))
    })
  }

  const getFormData = async () => {
    const moduleData = await getModuleData();
    const data = [];
    moduleData.forEach((item) => {
      data.push({
        value: item.source_id,
        label: item.source_name
      })
    })
    setModuleData(data);
    if (token) {
      axios.get(Settings.baseUrl + `template/details?template_id=${token}`).then((res) => {
        if (res.data.success) {
          setFieldDictionaryData(res.data.data);
          setTemplateName(res.data.data.name);
          setTemplateNameDescription(res.data.data.description);
          setForceRender(!forceRender);
          setLoading(false);
          Helper.alert(res.data.message);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }

  const onSaveClick = (newData) => {
    if (token) {
      updateTemplate(newData);
    } else {
      addTemplate(newData);
    }
  }

  const addTemplate = async (data) => {
    let validation = ValidateArray(data);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error')
    }
    const formData = [];
    const moduleId = document.getElementById('template_module_id').value;
    const templateName = document.getElementById('template_name_id').value;
    const templateNameDescription = document.getElementById('template_description_id').value;

    if (!moduleId) {
      Helper.alert('Please select a module', 'error');
      return;
    }
    if (!templateName) {
      Helper.alert('Please give template name', 'error');
      return;
    }

    setIsDisable(true)
    const tenantId = await getTenantId();

    formData.push({
      "tenant_id": tenantId,
      "module_id": moduleId ? parseInt(moduleId) : null,
      "name": templateName ? templateName : null,
      "description": templateNameDescription ? templateNameDescription : null,
      "field_dictionary": data
    })
    axios.post(Settings.baseUrl + 'template/create', formData).then((res) => {
      if (res.data.success) {
        setIsDisable(false)
        console.log(res.data.data);
        Helper.alert(res.data.message);
        navigate('/template');
      }
    }).catch((res) => {
      setIsDisable(false)
      res.response.data.errors[0].module_id[0] && Helper.alert(res.response.data.errors[0].module_id[0],'error');
    })
  };

  const updateTemplate = async (data) => {
    let validation = ValidateArray(data)
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error')
    }
    const formData = [];
    const fieldData = [];
    data.forEach((item) => {
      if (!item.field_id || item.field_id <= 0) {
        fieldData.push({
          "field_name": item.field_name,
          "field_label": item.field_label,
          "api_endpoint": item.api_endpoint,
          "endpoint_key": item.endpoint_key,
          "endpoint_value": item.endpoint_value,
          "field_type": item.field_type,
        })
      } else {
        fieldData.push({
          "field_id": item.field_id,
          "field_name": item.field_name,
          "field_label": item.field_label,
          "api_endpoint": item.api_endpoint,
          "endpoint_key": item.endpoint_key,
          "endpoint_value": item.endpoint_value,
          "field_type": item.field_type,
        })
      }
    });
    const templateName = document.getElementById('template_name_id').value;
    const templateNameDescription = document.getElementById('template_description_id').value;
    formData.push({
      "template_id": token,
      "module_id": document.getElementById('template_module_id') ? parseInt(document.getElementById('template_module_id').value) : null,
      "name": templateName ? templateName : null,
      "description": templateNameDescription ? templateNameDescription : null,
      "field_dictionary": fieldData
    })
    axios.post(Settings.baseUrl + 'template/update', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        navigate('/template');
      }
    }).catch((res) => {
      res.response.data.errors[0].non_field_errors[0] && Helper.alert(res.response.data.errors[0].non_field_errors[0],'error');
    })
  }

  const deleteFieldDictionary = (getFieldId) => {
    let formData = [];
    getFieldId.forEach((item) => {
      formData.push(item.field_id)
    });
    axios.post(Settings.baseUrl + 'field-dictionary/delete', { "field_id": formData }).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const saveHandler = () => {
    $('.templateFrom .rs_grid_btn_save').trigger('click');
  }
  
  return (
    <>
      <MasterComonent>
        <NavigationHeder backUrl='/template' title='Template'>
          <Button isDisable={isDisable} isActive={true} title='Save' onClick={saveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>
        <div className="card mb-3">
          <div className="card-body">
            <div className="p-2">
              <div className='row'>
                <div className='row label-form'>
                  <div className='col-lg-4 col-md-3'>
                    <DropDownInput label="Module Name" required={true} options={moduleData} id='template_module_id' value={fieldDictionaryData ? fieldDictionaryData.module_id : ""} />
                  </div>
                  <div className='col-lg-4 col-md-3'>
                    <Input inputId='template_name_id' required={true} label="Template Name" placeholder="Type template name here" onChange={(e) => { setTemplateName(e.target.value) }} value={templateName ? templateName : ""} />
                  </div>
                  <div className='col-lg-4 col-md-3'>
                    <Input inputId='template_description_id' label="Template Description" placeholder="Type template description here" onChange={(e) => { setTemplateNameDescription(e.target.value) }} value={templateNameDescription ? templateNameDescription : ""} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <SimpleLoading /> : null}
        <div className='rs_form_grid templateFrom'>
          <AgGridMemo data={fieldDictionaryData?.field_dictionary ? fieldDictionaryData.field_dictionary : []} templateForm={true} id="form-grid" onDelete={deleteFieldDictionary} onSave={onSaveClick} header={Template_Field_Dictionary_Tabele_Columns_Headings} forceRender={forceRender}/>
        </div>
      </MasterComonent>
    </>
  )
}

export default FieldDictionary;
