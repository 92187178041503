import React, { useEffect, useState } from 'react'
import Popup from '../../../components/Popup/Popup';
import Button from '../../../components/inc/Button';
import axios from 'axios';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import { useNavigate } from 'react-router-dom';

const TriggerOption = (props) => {
  const navigate = useNavigate();
  const [bodyIdFromRadioButton, setBodyIdFromRadioButton] = useState(null);
  const [triggersOptionValue, setTriggersOptionValue] = useState([]);
  const [modifiedTriggerOption, setModifiedTriggerOption] = useState([]);
  const [isDisable,setIsDisable]=useState(false)

  //all type of trigger execution on trigger modal 

  useEffect(() => {
    triggerOptionGet(props.workflowid);
  }, [])

  const checkInstance = () => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + `instance/check-by-body-id?body_id=${bodyIdFromRadioButton}`).then((res) => {
          if (res.status === 200) {
            return res.data.instance_exist;
          }
        }
        )).catch((res) => reject(
          console.log(res)
        ))
    })
  }

  const triggerOptionGet = (e) => {
    axios.get(Settings.baseUrl + `workflow-body/get?workflow_id=${e}`).then((res) => {
      if (res.data.success) {
        const data = res.data.data;
        setModifiedTriggerOption(data);
        const concatenatedTriggers = data.reduce((acc, cur) => [...acc, ...cur.triggers], []);
        setTriggersOptionValue(concatenatedTriggers);
        if (concatenatedTriggers.length === 3) {
          setGoForDesign(true);
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  // let operation = [];
  const triggerOptionHandler = (e) => {
    setDeleteButton(false);
    setEditeButton(false);
    let radiobtn = Array.from(document.querySelectorAll(".radio"));
    radiobtn.filter(item => item.checked).forEach((element) => element.checked = false);
    // let checkbox = Array.from(document.querySelectorAll(".check"));
    // operation = checkbox.filter(item => item.checked).map(item => +item.value);
    // console.log(operation);
    setGoForDesign(false);
  }

  const openWorkflowDesignAsPerTrigger = () => {
    if (goForDesign && bodyIdFromRadioButton) {
      navigate(`/workflow-element/workflow-element?id=${props.templateid}&wid=${props.workflowid}&to_id=${bodyIdFromRadioButton}`);
    } else {
      setIsDisable(true)
      let checkbox = Array.from(document.querySelectorAll(".check"));
      let operation = checkbox.filter(item => item.checked).map(item => +item.value);
      if (!operation.length) {
        Helper.alert("At first choose your option", 'error');
        setIsDisable(false)
        return;
      }
      const formData = {
        "workflow_id": props.workflowid,
        "trigger": operation,
      }
      axios.post(Settings.baseUrl + 'workflow-body/create', formData).then((res) => {
        if (res.data.success) {
          setIsDisable(false)
          Helper.alert(res.data.message);
          props.hideTriggerOption(false);
          navigate(`/workflow-element/workflow-element?id=${props.templateid}&wid=${props.workflowid}&to_id=${res.data.data.body_id}`);
        }
      }).catch((error) => {
        setIsDisable(false)
        console.log(error);
      })
    }
  }

  const triggerModalClose = () => {
    setIsDisable(false)
    setModifiedTriggerOption([]);
    setTriggersOptionValue([]);
    props.hideTriggerOption(false);
    setEditeButton(false);
    setBodyIdFromRadioButton(null);
    setEditAndSaveButton(false);
  }

  const [deleteButton, setDeleteButton] = useState(false);
  const [editButton, setEditeButton] = useState(false);
  const [goForDesign, setGoForDesign] = useState(false);
  const triggerWiseDesign = (e) => {
    setGoForDesign(true);
    axios.get(Settings.baseUrl + `workflow-body/details?body_id=${e.target.value}`).then((res) => {
      if (res.data.success) {
        const data = res.data.data.triggers;
        if (triggersOptionValue.length !== 3 || data.length > 1) {
          setEditeButton(true);
        } else {
          setEditeButton(false);
        }
      }
    }).catch((error) => {
      console.log(error);
    })
    setDeleteButton(true);
    setBodyIdFromRadioButton(e.target.value);
    let checkbox = Array.from(document.querySelectorAll(".check"));
    checkbox.forEach((element) => {
      element.checked = false;
    });
  }
  const [editAndSaveButton, setEditAndSaveButton] = useState(false);
  const triggerOptionEdit = async () => {
    setIsDisable(true)
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't edit", 'error');
      setIsDisable(false)
    } else {
      if (bodyIdFromRadioButton) {
        axios.get(Settings.baseUrl + `workflow-body/details?body_id=${bodyIdFromRadioButton}`).then((res) => {
          if (res.data.success) {
            const data = res.data.data.triggers;
            const newtemplateDataArry = triggersOptionValue.filter(item => data.indexOf(item) === -1);
            if (newtemplateDataArry.length < 2) {
              setTriggersOptionValue(newtemplateDataArry);
              setEditAndSaveButton(true);
              setDeleteButton(false);
              setEditeButton(false);
            }
          }
        }).catch((error) => {
          console.log(error);
          setIsDisable(false)
        })
      }
    }
  }

  const editWorkflowDesignAsPerTrigger = () => {
    setIsDisable(true)
    let checkbox = Array.from(document.querySelectorAll(".check"));
    let edit_operation = checkbox.filter(item => item.checked).map(item => +item.value);
    if (!edit_operation.length) {
      Helper.alert("At first selct option you want to edit", 'error');
      setIsDisable(false)
      return;
    }
    const formData = {
      "body_id": bodyIdFromRadioButton,
      "trigger": edit_operation,
    }
    axios.post(Settings.baseUrl + 'workflow-body/update', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        triggerModalClose();
      }
    }).catch((error) => {
      console.log(error);
      setIsDisable(false)
    })
  }

  const triggerOptionDelete = async () => {
    setIsDisable(true)
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't delete", 'error');
      setIsDisable(false)
    } else {
      axios.post(Settings.baseUrl + 'workflow-body/delete', { "body_id": [bodyIdFromRadioButton] }).then((res) => {
        if (res.data.success) {
          Helper.alert(res.data.message);
          setDeleteButton(false);
          triggerModalClose();
        }
      }).catch((error) => {
        setIsDisable(false)
        console.log(error);
      })
    }
  }

  return (
    <>
      <Popup onClose={triggerModalClose} autoOpen={true}>
        <h3>Trigger Option</h3>
        {modifiedTriggerOption.length ?
          <div>
            <p>Select design to go for workflow</p>
            {modifiedTriggerOption.map((item) => (
              <div className="d-flex">
                <input className="radio me-2" type="radio" disabled={editAndSaveButton ? true : false} name="triggerOption" value={item.body_id} id={item.body_id + "forcreate"} onClick={triggerWiseDesign} />
                <label className="" for={item.body_id + "forcreate"}>
                  {item.triggers_modified.join("/")}
                </label>
                {/* <Button isActive={true} title={item.triggers_modified.join("/")} /> */}
              </div>
            ))}
          </div> : ""}
        <div className="mt-3">
          <p>{editAndSaveButton ? 'Select the trigger option you want to edit.' : 'Which trigger option you want to add for this workflow?'}</p>
          <div className="d-flex" >
            <input className="check me-2" type="checkbox" value="1" id="create" disabled={triggersOptionValue.includes(1) ? true : false} onClick={triggerOptionHandler} />
            <label className="" for="create">
              Create
            </label>
          </div>
          <div className="d-flex">
            <input className="check me-2" type="checkbox" value="2" id="update" disabled={triggersOptionValue.includes(2) ? true : false} onClick={triggerOptionHandler} />
            <label className="" for="update">
              Update
            </label>
          </div>
          <div className="d-flex">
            <input className="check me-2" type="checkbox" value="3" id="delete" disabled={triggersOptionValue.includes(3) ? true : false} onClick={triggerOptionHandler} />
            <label className="" for="delete">
              Delete
            </label>
          </div>
        </div>
        <div className='d-flex justify-content-end mt-4 '>
          <Button className="me-2" title="Close" onClick={triggerModalClose} />
          {deleteButton && <Button isDisable={isDisable} className="me-2" title="Delete" onClick={triggerOptionDelete} />}
          {editButton && <Button isDisable={isDisable} className="me-2" title="Merge Operations" onClick={triggerOptionEdit} />}
          {editAndSaveButton ?
            <Button isDisable={isDisable} title='Save & Go' isActive={true} onClick={editWorkflowDesignAsPerTrigger} />
            :
            <Button isDisable={isDisable} title={goForDesign ? 'View' : 'Create & View'} isActive={true} onClick={openWorkflowDesignAsPerTrigger} />
          }
        </div>
      </Popup>
    </>
  )
}

export default TriggerOption;